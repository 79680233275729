/* You can add global styles to this file, and also import other style files */

/*================================================
Default Area CSS
=================================================*/
$font-family: 'Roboto', sans-serif;
$heading-font-family: 'Poppins', sans-serif;
$main-color: #9C09CA;
$optional-color: #22b663;
$white-color: #ffffff;
$black-color: #323232;
$paragraph-color: #666666;
$transition: .5s;
$font-size: 15px;

body {
    padding: 0;
    margin: 0;
    font: {
        family: $font-family;
        size: $font-size;
    }
}
.ptb-120 {
    padding: {
        top: 120px;
        bottom: 120px;
    }
}
.bg-fffcfb {
    background-color: #fffcfb;
}
.bg-fff7f4 {
    background-color: #fff7f4;
}
p {
    margin-bottom: 15px;
    color: $paragraph-color;
    font-size: $font-size;
    line-height: 1.8;

    &:last-child {
        margin-bottom: 0;
    }
}
img {
    max-width: 100%;
}
button {
    outline: 0 !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font-family;
}
a {
    display: block;
    text-decoration: none;
    transition: $transition;
    color: $black-color;
    outline: 0;

    &:hover {
        text-decoration: none;
        color: $main-color;
    }
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
/*btn*/
.btn {
    padding: 14px 28px;
    text-transform: uppercase;
    line-height: initial;
    border-radius: 0;
    transition: $transition;
    font: {
        size: 15px;
        weight: 500;
    }
    &.disabled, &:disabled {
        opacity: 1;
    }
}
.btn-primary {
    color: $white-color;
    background-color: $main-color;
    border-color: $main-color;

    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
        color: $main-color;
        border-color: $main-color;
        background-color: transparent;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    }
    &.disabled, &:disabled {
        color: $white-color;
        background-color: $main-color;
        border-color: $main-color;
    }
    &:hover, &:focus {
        color: $main-color;
        border-color: $main-color;
        background-color: transparent;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    }
}
.btn-secondary {
    background-color: transparent;
    color: $main-color;
    border-color: $main-color;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.02);

    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
        background-color: $main-color;
        color: $white-color;
        border-color: $main-color;
    }
    &.focus, &:focus {
        box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    }
    &.disabled, &:disabled {
        background-color: transparent;
        color: $main-color;
        border-color: $main-color;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.02);
    }
    &:hover, &:focus {
        background-color: $main-color;
        color: $white-color;
        border-color: $main-color;
    }
}
/*section-title*/
.section-title {
    position: relative;
    margin: {
        bottom: 60px;
    }
    .btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    span {
        display: block;
        color: $optional-color;
        text-transform: uppercase;
        margin-bottom: 12px;
        position: relative;
        padding-left: 15px;
        z-index: 1;
        padding: {
            top: 3px;
            bottom: 2px;
        }
        font: {
            size: 15px;
            weight: 400;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 50px;
            background: $optional-color;
            z-index: -1;
            opacity: .20;
        }
        &.bg-f59f00 {
            color: #f59f00;

            &::before {
                background: #f59f00;
            }
        }
        &.bg-0018ff {
            color: #0018ff;

            &::before {
                background: #0018ff;
            }
        }
        &.bg-ff5d24 {
            color: $main-color;

            &::before {
                background: $main-color;
            }
        }
    }
    h2 {
        margin-bottom: 0;
        max-width: 640px;
        font: {
            weight: 700;
            size: 38px;
        }
    }
    p {
        max-width: 580px;
        margin: {
            bottom: 0;
            top: 12px;
        }
    }
    &.text-center {
        span {
            display: inline-block;
        }
        h2 {
            margin: {
                left: auto;
                right: auto;
            }
        }
        p {
            max-width: 665px;
            margin: {
                bottom: 0;
                top: 15px;
                left: auto;
                right: auto;
            }
        }
    }
}
/*form-control*/
.form-group {
    margin-bottom: 15px;
}
.form-control {
    height: 45px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: initial;
    color: $black-color;
    background-color: transparent;
    border: 1px solid #eeeeee;
    border-radius: 0;
    transition: $transition;

    &:focus {
        box-shadow: unset;
        border-color: $main-color;
    }
    &::placeholder {
        color: #5f5f5f;
    }
}
textarea.form-control {
    min-height: auto;
    height: auto;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $main-color;
    z-index: 9999999;
    text-align: center;

    .loader {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        animation: load 1.2s infinite 0s ease-in-out;
        animation-direction: alternate;
        color: $white-color;
        top: 45%;
        transform: translateY(-45%);
        font: {
            size: 30px;
            weight: 700;
        }
    }
}
@keyframes load {
    0% {
        opacity: 0.08;
        filter: blur(5px);
        letter-spacing: 3px;
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.zylon-mobile-nav {
    display: none;

    &.mean-container {
        .mean-nav {
            ul {
                li {
                    a {
                        &.active {
                            color: $main-color;
                        }
                    }
                }
            }
        }
    }
}
.navbar-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.navbar-brand {
    padding: {
        top: 0;
        bottom: 0;
    }
}
.zylon-nav {
    background-color: transparent;
    padding: {
        top: 15px;
        bottom: 15px;
    }
    .navbar {
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin-left: auto;

            .nav-item {
                position: relative;
                padding: {
                    top: 15px;
                    bottom: 15px;
                    left: 0;
                    right: 0;
                }
                a {
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                    color: $black-color;
                    text-transform: uppercase;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                    margin: {
                        left: 15px;
                        right: 15px;
                    }
                    &:hover, &:focus, &.active {
                        color: $main-color;
                    }
                    i {
                        font-size: 11px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &:focus, &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    padding: {
                        top: 20px;
                        left: 5px;
                        right: 5px;
                        bottom: 20px;
                    }
                    li {
                        padding: 0;

                        a {
                            text-transform: capitalize;
                            padding: 8px 15px;
                            margin: 0;
                            color: $black-color;
                            font: {
                                size: 15px;
                            }
                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            left: 220px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;

                            li {
                                a {
                                    color: $black-color;

                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 220px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;

                                    li {
                                        a {
                                            color: $black-color;
                                            text-transform: capitalize;

                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;

                                            li {
                                                a {
                                                    color: $black-color;
                                                    text-transform: capitalize;

                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                            }
                                            .dropdown-menu {
                                                left: -250px;
                                                top: 0;
                                                opacity: 0;
                                                visibility: hidden;

                                                li {
                                                    a {
                                                        color: $black-color;
                                                        text-transform: capitalize;

                                                        &:hover, &:focus, &.active {
                                                            color: $main-color;
                                                        }
                                                    }
                                                }
                                            }
                                            &.active {
                                                a {
                                                    color: $main-color;
                                                }
                                            }
                                            &:hover {
                                                .dropdown-menu {
                                                    opacity: 1;
                                                    visibility: visible;
                                                    top: -15px;
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: -15px;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }
        }
    }
    .others-options {
        margin-left: 27px;

        .cart-btn {
            top: 2px;
            display: inline-block;
            margin-right: 30px;
            position: relative;

            i {
                &::before {
                    font-size: 18px;
                }
            }
            span {
                background-color: $black-color;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                line-height: 15px;
                display: inline-block;
                color: $white-color;
                text-align: center;
                position: absolute;
                right: -10px;
                top: 0;
                font: {
                    size: 7px;
                    weight: 500;
                }
            }
        }
        .languages-list {
            position: relative;
            top: 1px;
            display: inline-block;
            margin-right: 20px;

            .nice-select {
                border-radius: 0;
                border: none;
                float: unset;
                font-size: 14px;
                font-weight: 500;
                height: auto;
                line-height: initial;
                outline: none;
                text-transform: uppercase;
                padding: {
                    left: 0;
                    right: 10px;
                }
                .list {
                    background-color: $white-color;
                    border: none;
                    border-radius: 0;
                    box-shadow: unset;
                    margin-top: 5px;
                    left: -24px;
                    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);

                    .option {
                        line-height: initial;
                        min-height: unset;
                        margin-top: 10px;
                        padding: {
                            left: 25px;
                            right: 25px;
                        }
                        &:hover, &.focus, &.selected.focus {
                            background-color: transparent;
                        }
                        &:last-child {
                            margin-bottom: 10px;
                        }
                    }
                }
                &:after {
                    right: 0;
                }
            }
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    position: relative;
    z-index: 1;
    padding: {
        top: 115px;
        bottom: 70px;
    }
}
.main-banner-content {
    h1 {
        margin-bottom: 0;
        font: {
            size: 57px;
            weight: 700;
        }
    }
    p {
        margin: {
            top: 12px;
            bottom: 0;
        }
    }
    .btn {
        margin-top: 20px;
    }
    span {
        display: inline-block;
        color: #666666;
        background-color: #E6E6E6;
        font-weight: 600;
        padding: 9px 30px;
        border-radius: 30px;
        margin-bottom: 15px;
    }
}
.banner-image {
    position: relative;
    z-index: 1;
    text-align: center;

    img {
        &:nth-child(2) {
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: -1;
            opacity: .40;
            animation: movebounce 5s linear infinite;
        }
    }
}
.shape-img1 {
    position: absolute;
    left: 5%;
    top: 8%;
    z-index: -1;
}
.shape-img2 {
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    animation: movebounce 5s linear infinite;
}
.shape-img3 {
    position: absolute;
    left: 4%;
    bottom: 0;
    z-index: -1;
}
.shape-img4 {
    position: absolute;
    left: 25%;
    bottom: 5%;
    z-index: -1;
}
.shape-img5 {
    position: absolute;
    left: 25%;
    top: 12%;
    z-index: -1;
    animation: rotate3d 4s linear infinite;
}
.shape-img6 {
    position: absolute;
    left: 35%;
    top: 5%;
    z-index: -1;
    animation: movebounce 5s linear infinite;
}
.shape-img7 {
    position: absolute;
    right: 7%;
    top: 10%;
    z-index: -1;
    animation: movebounce 9s linear infinite;
}
.shape-img8 {
    position: absolute;
    right: 2%;
    bottom: 15%;
    z-index: -1;
    animation: movebounce 5s linear infinite;
}
.rotateme {
    animation-name: rotateme;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform:  translate(0px,0px)  rotate(0deg) ;
    }
    20% {
        transform:  translate(73px,-1px)  rotate(36deg) ;
    }
    40% {
        transform:  translate(141px,72px)  rotate(72deg) ;
    }
    60% {
        transform:  translate(83px,122px)  rotate(108deg) ;
    }
    80% {
        transform:  translate(-40px,72px)  rotate(144deg) ;
    }
    100% {
        transform:  translate(0px,0px)  rotate(0deg) ;
    }
}

/*================================================
Boxes Area CSS
=================================================*/
.boxes-area {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        height: 150px;
        box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.08);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        background: $white-color;
        margin: {
            left: 140px;
            right: 140px;
        }
    }
}
.single-box {
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: $main-color;
    padding: 25px;
    overflow: hidden;
    box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.02);

    .icon {
        color: $white-color;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            &::before {
                font-size: 45px;
            }
        }
    }
    h3 {
        margin: {
            top: 10px;
            bottom: 0;
        }
        color: $white-color;
        font: {
            size: 20px;
            weight: 700;
        }
    }
    p {
        color: $white-color;
        margin: {
            top: 13px;
            bottom: 0;
        }
    }
    .learn-more-btn {
        margin-top: 17px;
        color: $white-color;

        &::before {
            background: $white-color;
        }
    }
    .shape-box {
        img {
            &:nth-child(1) {
                position: absolute;
                left: -60px;
                top: -60px;
                z-index: -1;
            }
            &:nth-child(2) {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }
    }
    &.bg-71009b {
        background-color: #71009b;

        .shape-box {
            img {
                &:nth-child(1) {
                    left: auto;
                    right: -60px;
                    top: -60px;
                }
                &:nth-child(2) {
                    right: auto;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
    &.bg-bf00b8 {
        background-color: #bf00b8;

        .shape-box {
            img {
                &:nth-child(1) {
                    left: auto;
                    right: -60px;
                    top: auto;
                    bottom: -60px;
                }
                &:nth-child(2) {
                    bottom: auto;
                    left: -25px;
                    top: -25px;
                    transform: rotate(180deg);
                }
            }
        }
    }
    &.bg-471b9e {
        background-color: #471B9E;

        .shape-box {
            img {
                &:nth-child(1) {
                    left: auto;
                    right: -60px;
                    top: auto;
                    bottom: -60px;
                }
                &:nth-child(2) {
                    bottom: auto;
                    left: -25px;
                    top: -25px;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;
}
.about-content {
    padding-left: 30px;

    span {
        display: block;
        color: $main-color;
        text-transform: uppercase;
        margin-bottom: 12px;
        position: relative;
        z-index: 1;
        padding-left: 15px;
        padding: {
            top: 3px;
            bottom: 2px;
        }
        font: {
            size: 15px;
            weight: 400;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 50px;
            background: $main-color;
            z-index: -1;
            opacity: .20;
        }
    }
    h2 {
        margin-bottom: 0;
        font: {
            weight: 700;
            size: 38px;
        }
    }
    p {
        margin: {
            top: 12px;
            bottom: 0;
        }
    }
    .about-features-list {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
            top: 10px;
        }
        li {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            font-weight: 500;
            color: $paragraph-color;
            padding: {
                right: 15px;
                left: 15px;
                top: 10px;
            }
            i {
                color: #ffb923;
                margin-right: 2px;
            }
        }
    }
    .btn-box {
        margin-top: 25px;
    }
    .video-btn {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 49px;
        text-align: center;
        border: 1px solid $main-color;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        top: 3.5px;
        margin: {
            left: 25px;
        }
        i {
            &::before {
                font-size: 20px;
            }
        }
        &::after {
            z-index: -1;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 50px;
            height: 50px;
            animation: ripple 1.6s ease-out infinite;
            opacity: 0;
            border-radius: 50%;
            background: rgba(255, 93, 36, .3);
        }
        &:hover, &:focus {
            color: $white-color;
            background-color: $main-color;
        }
    }
}
.about-company-content {
    span {
        display: block;
        color: $main-color;
        text-transform: uppercase;
        margin-bottom: 12px;
        position: relative;
        z-index: 1;
        padding-left: 15px;
        padding: {
            top: 3px;
            bottom: 2px;
        }
        font: {
            size: 15px;
            weight: 400;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 50px;
            background: $main-color;
            z-index: -1;
            opacity: .20;
        }
    }
    h2 {
        margin-bottom: 15px;
        font: {
            weight: 700;
            size: 38px;
        }
    }
}
.about-our-company-inner {
    margin-top: 120px;
    z-index: 1;
    position: relative;
    background-color: $white-color;

    &::before {
        z-index: -1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 93%;
        content: '';
        position: absolute;
        background: #fff7f4;
    }
}
.about-inner-company-content {
    h2 {
        margin-bottom: 18px;
        font: {
            weight: 700;
            size: 38px;
        }
    }
}
.bg-shape1 {
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 25px;

    img {
        max-width: 80%;
    }
}
.shape-img9 {
    z-index: -1;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
@keyframes ripple {
    0%, 35% {
        transform: scale(0);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    padding-bottom: 90px;
}
.single-services-box {
    background-color: $white-color;
    box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.02);
    padding: 30px;
    border-radius: 3px;
    margin-bottom: 30px;
    transition: $transition;

    .icon {
        display: inline-block;
        width: 80px;
        height: 80px;
        line-height: 80px;
        background: linear-gradient(to bottom, #ffdfd4, #ffe6e0, #ffeeeb, #fff6f5, #fffefe);
        text-align: center;
        border-radius: 50%;
        color: $main-color;
        transition: $transition;

        i {
            &::before {
                font-size: 38px;
            }
        }
        &.bg-24b765 {
            color: #24b765;
            background: linear-gradient(to bottom, #beead1, #c9f0e1, #d9f5ee, #eaf9f8, #fdfefe);
        }
        &.bg-f59f00 {
            color: #f59f00;
            background: linear-gradient(to bottom, #fdeccc, #fef0d7, #fef4e1, #fff8ec, #fffcf7);
        }
        &.bg-f7b232 {
            color: #f7b232;
            background: linear-gradient(to bottom, #fdeccc, #feefd6, #fff3df, #fff6e9, #fffaf3);
        }
        &.bg-fe5d24 {
            color: #fe5d24;
            background: linear-gradient(to bottom, #feded2, #fee5db, #ffede5, #fff4ef, #fffbf9);
        }
        &.bg-45c27c {
            color: #45c27c;
            background: linear-gradient(to bottom, #beead1, #c9f0e1, #d9f5ee, #eaf9f8, #fdfefe);
        }
    }
    h3 {
        margin: {
            top: 20px;
            bottom: 0;
        }
        font: {
            size: 20px;
            weight: 700;
        }
    }
    p {
        margin: {
            top: 12px;
            bottom: 0;
        }
    }
    .learn-more-btn {
        margin-top: 15px;
    }
    &:hover {
        transform: translateY(-5px);

        .icon {
            transform: rotateY(-180deg);
        }
    }
}
.services-box {
    text-align: center;
    background-color: $white-color;
    box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.02);
    padding: 30px;
    border-radius: 3px;
    margin-bottom: 30px;
    transition: $transition;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .icon {
        display: inline-block;
        width: 80px;
        height: 80px;
        line-height: 80px;
        background: linear-gradient(to bottom, #ffdfd4, #ffe6e0, #ffeeeb, #fff6f5, #fffefe);
        text-align: center;
        border-radius: 50%;
        color: $main-color;
        transition: $transition;

        i {
            &::before {
                font-size: 38px;
            }
        }
        &.bg-24b765 {
            color: #24b765;
            background: linear-gradient(to bottom, #beead1, #c9f0e1, #d9f5ee, #eaf9f8, #fdfefe);
        }
        &.bg-f59f00 {
            color: #f59f00;
            background: linear-gradient(to bottom, #fdeccc, #fef0d7, #fef4e1, #fff8ec, #fffcf7);
        }
        &.bg-f7b232 {
            color: #f7b232;
            background: linear-gradient(to bottom, #fdeccc, #feefd6, #fff3df, #fff6e9, #fffaf3);
        }
        &.bg-fe5d24 {
            color: #fe5d24;
            background: linear-gradient(to bottom, #feded2, #fee5db, #ffede5, #fff4ef, #fffbf9);
        }
        &.bg-45c27c {
            color: #45c27c;
            background: linear-gradient(to bottom, #beead1, #c9f0e1, #d9f5ee, #eaf9f8, #fdfefe);
        }
    }
    h3 {
        transition: $transition;
        margin: {
            top: 20px;
            bottom: 0;
        }
        font: {
            size: 20px;
            weight: 700;
        }
    }
    p {
        transition: $transition;
        margin: {
            top: 12px;
            bottom: 0;
        }
    }
    .learn-more-btn {
        margin-top: 15px;
    }
    img {
        position: absolute;
        right: -100px;
        top: -100px;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    &:hover {
        background-color: $main-color;
        transform: translateY(-5px);

        .icon {
            transform: rotateY(-180deg);
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .learn-more-btn {
            color: $white-color;

            &::before {
                background: $white-color;
            }
        }
        img {
            opacity: 1;
            visibility: visible;
            right: -80px;
            top: -80px;
        }
    }
}
.learn-more-btn {
    display: inline-block;
    color: $main-color;
    font-weight: 600;
    position: relative;
    z-index: 1;

    i {
        margin-left: 3px;

        &::before {
            font-size: 13px;
        }
    }
    &::before {
        width: 100%;
        height: 1px;
        background: $main-color;
        content: '';
        bottom: 1.5px;
        left: 0;
        position: absolute;
        transition: $transition;
    }
    &:hover {
        &::before {
            left: auto;
            right: 0;
            width: 0;
        }
    }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details {
    .services-details-desc {
        margin-top: 25px;

        h3 {
            margin-bottom: 15px;
            line-height: 1.5;
            font: {
                size: 20px;
                weight: 700;
            }
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
        .services-details-features-list {
            background-color: $white-color;
            padding: 40px;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            margin-top: 30px;

            ul {
                padding-left: 0;
                list-style-type: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: {
                    bottom: 0;
                    left: -15px;
                    right: -15px;
                    top: -12px;
                }
                li {
                    margin-top: 12px;
                    color: $paragraph-color;
                    -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: {
                        right: 15px;
                        left: 15px;
                    }
                    i {
                        color: $main-color;
                        margin-right: 2px;

                        &::before {
                            font-size: 13px;
                        }
                    }
                    &:nth-child(1), &:nth-child(6), &:nth-child(10) {
                        i {
                            color: $optional-color;
                        }
                    }
                    &:nth-child(4), &:nth-child(7) {
                        i {
                            color: #f8c363;
                        }
                    }
                    &:nth-child(5), &:nth-child(9) {
                        i {
                            color: #545454;
                        }
                    }
                }
            }
        }
        .services-details-faq {
            margin-top: 25px;

            .accordion {
                list-style-type: none;
                padding: 0;
                margin: 0;

                .accordion-item {
                    display: block;
                    margin-bottom: 20px;
                    border: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    .accordion-title {
                        color: $black-color;
                        position: relative;
                        font: {
                            size: 16px;
                            weight: 600;
                        }
                    }
                }
                .accordion-content {
                    margin-top: 8px;
                }
            }
        }
    }
}
.services-sidebar {
    .services-list {
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background-color: $white-color;
        padding: 20px;
        margin-bottom: 20px;

        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;

            li {
                margin-bottom: 7px;

                a {
                    font-weight: 500;
                    padding: 10px 12px;
                    background-color: #d0eedd;
                    color: $optional-color;
                    position: relative;

                    i {
                        position: absolute;
                        right: 12px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:hover, &.active {
                        background-color: $optional-color;
                        color: $white-color;
                        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
                    }
                }
                &:nth-child(2), &:nth-child(4), &:nth-child(7) {
                    a {
                        color: $main-color;
                        background-color: #fddcd1;

                        &:hover, &.active {
                            color: $white-color;
                            background-color: $main-color;
                        }
                    }
                }
                &:nth-child(3), &:nth-child(5) {
                    a {
                        color: #f59f00;
                        background-color: #fbe9ca;

                        &:hover, &.active {
                            color: $white-color;
                            background-color: #f59f00;
                        }
                    }
                }
            }
        }
    }
    .services-download-list {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 10px;

                a {
                    background-color: $white-color;
                    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
                    font-weight: 500;
                    padding: 14px 15px;

                    &:hover {
                        background-color: $optional-color;
                        color: $white-color;
                    }
                    i {
                        margin-right: 2px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*================================================
Appointment Area CSS
=================================================*/
.appointment-inner-area {
    position: relative;
    background: {
        image: url(assets/img/appointment-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
}
.appointment-form {
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.02);
    max-width: 455px;
    margin-left: auto;
    padding: 40px;
    position: relative;
    z-index: 1;
    top: 40px;

    &::before {
        left: 0;
        right: 0;
        height: 20px;
        content: '';
        position: absolute;
        background: #f4f4f4;
        bottom: -20px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.02);
        border-radius: 0 0 5px 5px;
        margin: {
            left: 15px;
            right: 15px;
        }
    }
    h3 {
        margin-bottom: 25px;
        text-transform: uppercase;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    form {
        .btn {
            display: block;
            width: 100%;
            margin-top: 20px;
        }
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    padding: {
        bottom: 20px;
        top: 50px;
    }
    &.extra-pt {
        padding-top: 115px;
    }
}
.partner-item {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    width: 100%;
    position: relative;
    padding: {
        right: 15px;
        left: 15px;
    }
}
.single-partner {
    text-align: center;
    margin-bottom: 30px;

    a {
        img {
            transition: $transition;
            opacity: .40;
        }
    }
    &:hover {
        a {
            img {
                opacity: 1;
            }
        }
    }
}

/*================================================
Performance Area CSS
=================================================*/
.performance-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.performance-content {
    .section-title {
        margin-bottom: 40px;
    }
    .performance-progress-list {
        .single-progress {
            position: relative;
            margin-bottom: 40px;
            padding-left: 110px;

            &:last-child {
                margin-bottom: 0;
            }
            .progress-count {
                background-color: transparent;
                color: $black-color;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            .progress-content {
                h3 {
                    text-transform: uppercase;
                    margin-bottom: 0;
                    font: {
                        weight: 700;
                        size: 18px;
                    }
                }
                p {
                    margin: {
                        bottom: 0;
                        top: 8px;
                    }
                }
            }
        }
    }
}
.bg-shape2 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    text-align: right;

    img {
        max-width: 80%;
    }
}
.shape-img10 {
    position: absolute;
    z-index: -1;
    left: -40px;
    bottom: 17%;
    animation: movebounce 5s linear infinite;
}
.shape-img11 {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 30%;
}

/*================================================
Our Progress Area CSS
=================================================*/
.our-progress-area {
    padding-bottom: 90px;
}
.single-progress-box {
    text-align: center;
    margin-bottom: 30px;

    .progress-bar {
        background-color: transparent;
        display: inline-block;
        color: $black-color;
    }
    h3 {
        margin: {
            bottom: 10px;
            top: 15px;
        }
        text-transform: uppercase;
        font: {
            size: 18px;
            weight: 700;
        }
    }
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-area {
    background-color: $main-color;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: {
        top: 50px;
        bottom: 50px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: -1;
        background: $main-color;
        width: 88%;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: -1;
        background: $white-color;
        width: 12%;
    }
}
.single-funfacts {
    text-align: center;

    h3 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 54px;
            weight: 700;
        }
        .odometer-formatting-mark {
            display: none;
        }
    }
    p {
        line-height: initial;
        color: $white-color;
        font-size: 16px;
        margin: {
            bottom: 0;
            top: 3px;
        }
    }
}
.map-box1 {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
    position: relative;
    z-index: 1;
    padding-top: 90px;

    &::before {
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;
        background: $white-color;
        z-index: -1;
        position: absolute;
    }
    .section-title {
        margin: {
            bottom: 30px;
            top: 30px;
        }
    }
}
.single-pricing-box {
    text-align: center;
    margin-top: 30px;
    padding: 40px 25px;
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);

    .icon {
        display: inline-block;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        background-color: #ffc6b2;
        color: $main-color;
        line-height: 110px;
        transition: $transition;

        i {
            &::before {
                font-size: 45px;
            }
            &.flaticon-health {
                padding-left: 9px;
            }
        }
    }
    h3 {
        margin: {
            bottom: 0;
            top: 30px;
        }
        font: {
            size: 20px;
            weight: 700;
        }
    }
    .pricing-features-list {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 25px;
            bottom: 0;
        }
        li {
            color: $paragraph-color;
            margin-bottom: 12px;

            i {
                color: $main-color;
                margin-right: 2px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .price {
        margin-top: 18px;
        font: {
            size: 40px;
            weight: 700;
        }
        span {
            font: {
                size: $font-size;
                weight: normal;
            }
            display: inline-block;
            margin-left: -6px;
            color: $paragraph-color;
        }
    }
    .default-btn {
        display: inline-block;
        position: relative;
        margin-top: 20px;
        z-index: 1;
        padding: 12px 35px 10px;
        color: $main-color;
        text-transform: uppercase;
        font-weight: 500;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background: $main-color;
            opacity: .30;
            height: 100%;
            width: 100%;
            left: 0;
            z-index: -1;
            transition: $transition;
        }
        &:hover {
            color: $white-color;

            &::before {
                opacity: 1;
            }
        }
    }
    &.bg-3fc581 {
        .icon {
            background-color: #beead1;
            color: $optional-color;
        }
        .pricing-features-list {
            li {
                i {
                    color: $optional-color;
                }
            }
        }
        .default-btn {
            color: $optional-color;

            &::before {
                background: $optional-color;
            }
        }
        &:hover {
            .icon {
                background-color: $optional-color;
                color: $white-color;
            }
        }
    }
    &.bg-f59f00 {
        .icon {
            background-color: #fce2b2;
            color: #f59f00;
        }
        .pricing-features-list {
            li {
                i {
                    color: #f59f00;
                }
            }
        }
        .default-btn {
            color: #f59f00;

            &::before {
                background: #f59f00;
            }
        }
        &:hover {
            .icon {
                background-color: #f59f00;
                color: $white-color;
            }
        }
    }
    &:hover {
        .icon {
            background-color: $main-color;
            color: $white-color;
        }
        .default-btn {
            color: $white-color;

            &::before {
                opacity: 1;
            }
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    position: relative;
    z-index: 1;
    padding-bottom: 90px;
    background: {
        image: url(assets/img/team-shape-bg.png);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
}
.single-team-member {
    text-align: center;
    margin-bottom: 30px;

    .member-image {
        position: relative;
        border-radius: 50%;

        img {
            border-radius: 50%;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $main-color;
            opacity: 0;
            transition: $transition;
            visibility: hidden;
        }
        .details-btn {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            color: $white-color;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid $white-color;
            margin: 0 auto;
            line-height: 38px;
            margin-top: 20px;
            opacity: 0;
            visibility: hidden;

            &:hover {
                background-color: $optional-color;
                color: $white-color;
                border-color: $optional-color;
            }
        }
    }
    .member-content {
        margin-top: 25px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 21px;
                weight: 700;
            }
            a {
                color: $black-color;
            }
        }
        span {
            display: block;
            color: $main-color;
            margin-top: 8px;
        }
    }
    &:hover {
        .member-image {
            &::before {
                opacity: .60;
                visibility: visible;
            }
            .details-btn {
                opacity: 1;
                visibility: visible;
                margin-top: 0;
            }
        }
    }
}

/*================================================
Team Details Area CSS
=================================================*/
.team-details-sidebar {
    .team-profile {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);

        .team-content {
            padding: 25px;

            h3 {
                margin-bottom: 0;
                font: {
                    size: 20px;
                    weight: 700;
                }
            }
            p {
                margin: {
                    bottom: 0;
                    top: 12px;
                }
            }
            span {
                display: block;
                color: $main-color;
                margin-top: 10px;
            }
        }
    }
    .social-box {
        margin-top: 25px;
        background-color: $white-color;
        padding: 25px;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 700;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: 20px;
            }
            li {
                margin-right: 4px;
                display: inline-block;

                a {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    border: 1px solid #dadada;
                    border-radius: 50%;
                    color: $black-color;
                    font-size: 14px;
                    text-align: center;

                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .call-to-action-box {
        margin-top: 25px;

        a {
            background-color: $main-color;
            color: $white-color;
            box-shadow: 0 2px 28px 0 rgba(255, 93, 36, 0.4);
            padding: 25px;
            border-radius: 5px;
            position: relative;
            padding-left: 90px;

            h3 {
                margin-bottom: 0;
                font: {
                    size: 20px;
                    weight: 700;
                }
            }
            i {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 45px;
            }
            span {
                display: block;
                margin-top: 10px;
                font-size: 16px;
            }
        }
    }
}
.team-details-desc {
    background-color: $white-color;
    padding: 25px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);

    h3 {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        position: relative;
        font: {
            size: 20px;
            weight: 700;
        }
        &::before {
            width: 50px;
            height: 1px;
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            background: $main-color;
        }
    }
    p {
        font-size: 14.5px;
    }
    .team-info {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 20px;
            bottom: 0;
        }
        li {
            position: relative;
            padding-left: 170px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            margin-bottom: 20px;

            span {
                font-weight: 700;
                color: $black-color;
                position: absolute;
                left: 0;
                top: 0;
            }
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    margin-bottom: 10px;
                    color: $paragraph-color;
                    font-size: 13.5px;
                    padding-left: 17px;
                    border-bottom: none;
                    padding-bottom: 0;

                    &::before {
                        content: "\f100";
                        position: absolute;
                        left: 0;
                        top: 3px;
                        color: $optional-color;
                        font: {
                            weight: 900;
                            family: "Font Awesome 5 Free";
                            size: 10px;
                        }
                    }
                    a {
                        color: $paragraph-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-child(1), &:nth-child(4) {
                        &::before {
                            color: $optional-color;
                        }
                    }
                    &:nth-child(2), &:nth-child(5) {
                        &::before {
                            color: $main-color;
                        }
                    }
                    &:nth-child(3), &:nth-child(6) {
                        &::before {
                            color: #f59f00;
                        }
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.team-details-contact {
    background-color: $white-color;
    padding: 25px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    margin-top: 25px;

    h3 {
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        position: relative;
        font: {
            size: 20px;
            weight: 700;
        }
        &::before {
            width: 50px;
            height: 1px;
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            background: $main-color;
        }
    }
}

/*================================================
Testimonial Area CSS
=================================================*/
.testimonial-area {
    padding-bottom: 90px;

    .section-title {
        margin-bottom: 30px;
    }
}
.single-testimonial-item {
    position: relative;
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    padding: 30px;
    z-index: 1;
    margin: {
        bottom: 30px;
        top: 30px;
    }
    p {
        margin-bottom: 0;
        font: {
            size: 16px;
            style: italic;
        }
    }
    .client-info {
        margin-top: 30px;
        position: relative;
        padding-left: 70px;

        img {
            width: 50px !important;
            height: 50px;
            border-radius: 50%;
            display: inline-block !important;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 17px;
                weight: 700;
            }
        }
        span {
            display: block;
            color: $paragraph-color;
            font-size: 14px;
            margin-top: 7px;
        }
    }
    i {
        position: absolute;
        color: $black-color;
        opacity: .15;
        right: 15px;
        bottom: -20px;
        z-index: -1;
        transition: $transition;

        &::before {
            font-size: 100px;
        }
    }
}
.owl-item {
    &.active {
        &.center {
            .single-testimonial-item {
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
.testimonial-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            line-height: initial;

            [class*=owl-] {
                width: 50px;
                height: 50px;
                line-height: 50px;
                border: 1px solid #6a6a6a;
                border-radius: 50%;
                transition: $transition;
                background-color: transparent;
                margin: 0;
                color: #323232;
                position: absolute;
                left: 100px;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                visibility: hidden;

                i {
                    &::before {
                        font-size: 20px;
                    }
                }
                &.owl-next {
                    left: auto;
                    right: 100px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    left: 155px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: 155px;
                    }
                }
            }
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    padding-bottom: 90px;

    .pagination-area {
        margin: {
            bottom: 30px;
            top: 40px;
        }
    }
}
.single-blog-post {
    margin-bottom: 30px;
    transition: $transition;

    .post-image {
        position: relative;
        overflow: hidden;

        a {
            img {
                transition: $transition;
            }
        }
        .date {
            position: absolute;
            left: 0;
            bottom: 0;
            color: $white-color;
            background-color: $main-color;
            padding: 5px 12px;
            font: {
                weight: 500;
                size: 14px;
            }
        }
    }
    .post-content {
        margin-top: 20px;

        span {
            color: $paragraph-color;
            display: block;

            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        h3 {
            line-height: 1.5;
            margin: {
                bottom: 0;
                top: 10px;
            }
            font: {
                size: 20px;
                weight: 700;
            }
        }
        p {
            margin: {
                bottom: 0;
                top: 10px;
            }
        }
        .learn-more-btn {
            margin-top: 12px;
        }
    }
    &:hover {
        .post-image {
            a {
                img {
                    transform: rotate(15deg) scale(1.4);
                }
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details {
    .article-content {
        margin-top: 30px;

        .entry-meta {
            margin-bottom: 17px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    margin: 0 10px;
                    position: relative;
                    display: inline-block;
                    color: $paragraph-color;

                    a {
                        display: inline-block;
                        color: $paragraph-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        font-size: 16px;
                        margin-right: 2px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $paragraph-color;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        h3 {
            margin-bottom: 17px;
            font: {
                size: 20px;
                weight: 700;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: {
                    right: -15px;
                    left: -15px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    -ms-flex: 0 0 33.3333%;
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 15px;
                        left: 15px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .article-features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 15px;
                left: 20px;
            }
            li {
                margin-bottom: 12px;
                color: $paragraph-color;
                position: relative;
                padding-left: 15px;

                &::before {
                    background: $black-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    position: absolute;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        }
        .article-tags {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;

            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .article-share {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;

            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    margin-left: 5px;

                    a {
                        position: relative;
                        color: $white-color;
                        background-color: #959c98;
                        width: 33px;
                        height: 33px;
                        line-height: 33px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 13px;
                        z-index: 1;

                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                        }
                    }
                }
            }
        }
    }
    .post-navigation {
        margin-top: 30px;
    }
}
.post-navigation {
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .nav-previous {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;

            a {
                i {
                    margin-right: 2px;
                }
            }
        }
        .nav-next {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;

            a {
                i {
                    margin-left: 2px;
                }
            }
        }
        div {
            a {
                display: inline-block;
                font: {
                    size: $font-size;
                    weight: 500;
                }
            }
        }
    }
}
blockquote, .blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: #666666;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 20px;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        content: "\f111";
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        font: {
            family: Flaticon;
            size: 140px;
            style: normal;
        }
    }
}
.comments-area {
    background-color: #f8f8f8;
    padding: 25px 20px;
    margin-top: 30px;

    .comments-title {
        position: relative;
        margin: {
            bottom: 30px;
        }
        line-height: initial;
        font: {
            size: 20px;
            weight: 700;
        }
        &::before {
            content: '';
            height: 25px;
            width: 3px;
            left: -20px;
            position: absolute;
            background: $main-color;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                position: relative;
                z-index: 1;
                font: {
                    size: 12px;
                    weight: 600;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-meta {
        margin-bottom: .8em;
    }
    .comment-author {
        font-size: 16px;
        margin-bottom: 0.4em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 600;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 11px;
            weight: 500;
        }
        a {
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-content {
        p {
            font-size: 14px;
        }
    }
    .comment-respond {
        .comment-reply-title {
            margin: 0;
            position: relative;
            font: {
                size: 20px;
                weight: 700;
            }
            &::before {
                content: '';
                height: 25px;
                width: 3px;
                left: -20px;
                position: absolute;
                background: $main-color;
                top: 50%;
                transform: translateY(-50%);
            }
            #cancel-comment-reply-link {
                font-size: 15px;
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            font-size: 15px;
            margin: {
                bottom: 0;
                top: 10px;
            }
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            font-weight: 600;
            color: $black-color;
            margin-bottom: 3px;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;

            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 6px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 10px 30px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: uppercase;
                transition: $transition;
                font: {
                    weight: 600;
                    size: 14px;
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $optional-color;
                }
            }
        }
    }
}

/*================================================
CTA Area CSS
=================================================*/
.cta-area {
    background-color: $main-color;
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/cta-bg.png);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
}
.cta-content {
    text-align: center;
    max-width: 900px;
    margin: {
        left: auto;
        right: auto;
    }
    h3 {
        font: {
            size: 38px;
            weight: 700;
        }
        margin-bottom: 0;
        color: $white-color;
    }
    p {
        color: $white-color;
        opacity: .95;
        max-width: 820px;
        margin: {
            bottom: 0;
            top: 18px;
            left: auto;
            right: auto;
        }
    }
    h2 {
        margin: {
            bottom: 0;
            top: 20px;
        }
        font: {
            size: 38px;
            weight: 700;
        }
        a {
            color: $white-color;
        }
    }
    .btn {
        margin-top: 30px;
    }
    .btn-primary {
        color: $main-color;
        background-color: $white-color;
        border-color: $white-color;

        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
            color: $white-color;
            border-color: $optional-color;
            background-color: $optional-color;
        }
        &:hover, &:focus {
            color: $white-color;
            border-color: $optional-color;
            background-color: $optional-color;
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    text-align: center;
    position: relative;
    z-index: 1;
    background: {
        color: $white-color;
        image: url(assets/img/page-title-bg.png);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    border-bottom: 1px solid #eeeeee;
    padding: {
        top: 160px;
        bottom: 90px;
    }
}
.page-title-content {
    h2 {
        text-transform: uppercase;
        margin-bottom: 0;
        font: {
            weight: 700;
            size: 30px;
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -15px;
        display: inline-block;
        background-color: $white-color;
        padding: 5px 15px;

        li {
            font-size: 16px;
            display: inline-block;
            position: relative;
            color: $main-color;
            margin: {
                left: 12px;
                right: 12px;
            };
            a {
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: "\f054";
                font: {
                    weight: 900;
                    family: "Font Awesome 5 Free";
                    size: 10px;
                }
                position: absolute;
                right: -15px;
                top: 50%;
                color: $black-color;
                transform: translateY(-50%);
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

/*================================================
Research Area CSS
=================================================*/
.research-area {
    padding-bottom: 90px;
}
.single-research-box {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);

    .research-image {
        overflow: hidden;

        a {
            img {
                transition: $transition;
            }
        }
    }
    .research-content {
        padding: 25px;

        span {
            display: inline-block;
            background-color: #ffe7de;
            color: $main-color;
            padding: 5px 15px;
            border-radius: 30px;
            font-size: 13px;
        }
        h3 {
            margin: {
                bottom: 0;
                top: 15px;
            }
            font: {
                size: 20px;
                weight: 700;
            }
        }
        p {
            margin: {
                bottom: 0;
                top: 12px;
            }
        }
    }
    &:hover {
        .research-image {
            a {
                img {
                    opacity: 0.7;
                }
            }
        }
    }
}

/*================================================
Research Details Area CSS
=================================================*/
.research-details-info {
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: $white-color;
    padding: 50px 40px;

    h3 {
        margin-bottom: 25px;
        font: {
            size: 20px;
            weight: 700;
        }
    }
    ul {
        margin-bottom: 0;
        list-style-type: none;
        border-top: 1px solid #eeeeee;
        padding: {
            left: 0;
            top: 25px;
        }
        li {
            margin-bottom: 20px;
            color: $paragraph-color;
            position: relative;
            padding-left: 53px;

            span {
                display: block;
                text-transform: uppercase;
                color: $black-color;
                margin-bottom: 4px;
                font: {
                    weight: 700;
                }
            }
            i {
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: $main-color;
                background-color: #ffdfd3;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:nth-child(2), &:nth-child(5) {
                i {
                    background-color: #d9f3e6;
                    color: $optional-color;
                }
            }
            &:nth-child(3), &:nth-child(6) {
                i {
                    background-color: #fdeccc;
                    color: #f59f00;
                }
            }
        }
    }
}
.research-details-desc {
    margin-top: 50px;

    h3 {
        margin-bottom: 12px;
        font: {
            size: 20px;
            weight: 700;
        }
        &:not(:first-child) {
            margin-top: 20px;
        }
    }
}
.research-details-features {
    margin-top: 50px;

    .features-box {
        background-color: $white-color;
        box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.02);
        padding: 30px;
        border-radius: 3px;
        transition: $transition;
        position: relative;
        z-index: 1;
        overflow: hidden;

        .icon {
            display: inline-block;
            width: 80px;
            height: 80px;
            line-height: 80px;
            background: linear-gradient(to bottom, #ffdfd4, #ffe6e0, #ffeeeb, #fff6f5, #fffefe);
            text-align: center;
            border-radius: 50%;
            color: $main-color;
            transition: $transition;

            i {
                &::before {
                    font-size: 38px;
                }
            }
            &.bg-24b765 {
                color: #24b765;
                background: linear-gradient(to bottom, #beead1, #c9f0e1, #d9f5ee, #eaf9f8, #fdfefe);
            }
            &.bg-f59f00 {
                color: #f59f00;
                background: linear-gradient(to bottom, #fdeccc, #fef0d7, #fef4e1, #fff8ec, #fffcf7);
            }
            &.bg-f7b232 {
                color: #f7b232;
                background: linear-gradient(to bottom, #fdeccc, #feefd6, #fff3df, #fff6e9, #fffaf3);
            }
            &.bg-fe5d24 {
                color: #fe5d24;
                background: linear-gradient(to bottom, #feded2, #fee5db, #ffede5, #fff4ef, #fffbf9);
            }
            &.bg-45c27c {
                color: #45c27c;
                background: linear-gradient(to bottom, #beead1, #c9f0e1, #d9f5ee, #eaf9f8, #fdfefe);
            }
        }
        h3 {
            transition: $transition;
            margin: {
                top: 20px;
                bottom: 0;
            }
            font: {
                size: 20px;
                weight: 700;
            }
        }
        p {
            transition: $transition;
            margin: {
                top: 12px;
                bottom: 0;
            }
        }
        img {
            position: absolute;
            right: -100px;
            top: -100px;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
        &:hover {
            background-color: $main-color;
            transform: translateY(-5px);

            .icon {
                transform: rotateY(-180deg);
            }
            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
            .learn-more-btn {
                color: $white-color;

                &::before {
                    background: $white-color;
                }
            }
            img {
                opacity: 1;
                visibility: visible;
                right: -80px;
                top: -80px;
            }
        }
    }
}
.research-details-desc-footer {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
        top: 50px;
        bottom: 50px;
    }
    .article-tags {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;

        span {
            display: inline-block;
            color: $black-color;
            font-size: 20px;
            margin-right: 5px;
            position: relative;
            top: 2px;
        }
        a {
            display: inline-block;
            background-color: #f8f8f8;
            color: $black-color;
            margin-right: 5px;
            padding: 5px 15px;
            font: {
                size: 13px;
                weight: 500;
            }
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
    .article-share {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;

        .social {
            padding-left: 0;
            list-style-type: none;
            text-align: right;
            margin: {
                bottom: 0;
            }
            li {
                display: inline-block;

                a {
                    position: relative;
                    color: $white-color;
                    background-color: #959c98;
                    width: 33px;
                    height: 33px;
                    line-height: 33px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 13px;
                    z-index: 1;
                    margin-left: 5px;

                    &:hover {
                        color: $white-color;
                        background-color: $main-color;
                    }
                }
            }
        }
    }
}
.related-project {
    margin-top: 80px;

    .section-title {
        margin-bottom: 20px;
    }
    .single-research-box {
        margin: {
            bottom: 0;
            top: 30px;
        }
    }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 30px;
    text-align: center;

    .page-numbers {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        display: inline-block;
        background-color: $white-color;
        line-height: 40px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 17px;
            weight: 600;
        }
        &.current, &:hover, &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: unset;
        }
    }
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area {
    padding-left: 15px;

    .widget {
        margin-top: 35px;

        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 20px;
            text-transform: capitalize;
            position: relative;
            font: {
                weight: 700;
                size: 19px;
            }
            &::before {
                content: '';
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
        &.widget_tag_cloud {
            .widget-title {
                margin-bottom: 12px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;

                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;

                &:hover {
                    color: $white-color;
                    background-color: $optional-color;
                }
            }
        }
    }
    .widget_zylon_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(assets/img/blog-img1.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog-img2.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog-img3.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;

                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: uppercase;
                    margin: {
                        top: 5px;
                        bottom: 5px;
                    }
                    font: {
                        size: 12px;
                        weight: 500;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font: {
                        size: 16px;
                        weight: 600;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                line-height: 1.5;
                font: {
                    weight: 500;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                line-height: 1.5;
                font: {
                    weight: 500;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                }
                span {
                    display: inline-block;
                }
                a {
                    display: inline-block;
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 15px;
                font: {
                    weight: 500;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                font: {
                    weight: 500;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                }
                a {
                    display: inline-block;
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 15px;
                font: {
                    size: $font-size;
                    weight: 500;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            font: {
                weight: 500;
                size: 14px !important;
            }
            padding: 7px 10px;
            border: 1px solid #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            }
            &:hover, &:focus {
                background-color: $main-color;
                color: $white-color;
                border-color: $main-color;
            }
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion {
    .accordion {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .accordion-item {
            display: block;
            margin-bottom: 30px;
            border: 0;

            &:last-child {
                margin-bottom: 0;
            }
            .accordion-title {
                color: $black-color;
                position: relative;
                font: {
                    size: 16px;
                    weight: 600;
                };
            }
        }
        .accordion-content {
            margin-top: 8px;
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
#contactForm {
    max-width: 950px;
    text-align: center;
    margin: {
        left: auto;
        right: auto;
    }
    .form-control {
        background-color: #f9f9f9;
        height: 50px;
        text-align: left;
        padding-left: 15px;

        &::placeholder {
            color: #909090;
        }
    }
    textarea.form-control {
        height: auto;
        padding: {
            top: 15px;
            left: 15px;
        }
    }
    .btn {
        margin-top: 15px;
    }
    .help-block {
        text-align: left;

        ul {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: 5px;
            }
            li {
                color: red;
            }
        }
    }
    #msgSubmit {
        text-align: center;
        margin-bottom: 0;

        &.text-danger, &.text-success {
            margin-top: 15px;
            font-size: 20px;
        }
    }
}
.contact-info {
    text-align: center;
    margin-top: 50px;

    .section-title {
        margin-bottom: 30px;

        h2 {
            margin-top: 0;
        }
    }
    .contact-info-content {
        h3 {
            margin-bottom: 12px;
            font: {
                size: 20px;
                weight: 700;
            }
        }
        h2 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 700;
            }
            a {
                color: $main-color;
                display: inline-block;
            }
        }
        .social {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: 15px;
            }
            li {
                display: inline-block;
                margin: 0 2px;

                a {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    border: 1px solid #dadada;
                    border-radius: 50%;
                    color: #aba5a5;

                    i {
                        font-size: 14px;
                    }
                    &:hover {
                        color: $white-color;
                        border-color: $main-color;
                        background-color: $main-color;
                    }
                }
            }
        }
    }
}
#map {
    margin-bottom: -8px;

    iframe {
        border: 0;
        width: 100%;
        height: 500px;
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    padding: {
        bottom: 90px;
        top: 150px;
    };
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        font: {
            size: 35px;
            weight: 700;
        }
        margin: {
            top: 30px;
            bottom: 17px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
    }
}

/*================================================
Shop Area CSS
=================================================*/
.woocommerce-topbar {
    margin-bottom: 40px;

    .woocommerce-result-count {
        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 700;
            }
        }
        p {
            margin: {
                bottom: 0;
                top: 5px;
            }
            font-size: 14px;
        }
    }
    .woocommerce-topbar-ordering {
        text-align: right;

        .nice-select {
            background-color: $white-color;
            color: $paragraph-color;
            border-radius: 0;
            border: none;
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            float: unset;
            height: unset;
            line-height: initial;
            padding: 18px 35px 18px 20px;
            font-size: 15px;

            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: $transition;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
    }
}
.single-product-box {
    margin-bottom: 30px;
    text-align: center;
    background-color: $white-color;
    box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.08);
    transition: $transition;

    .product-image {
        position: relative;

        .sale-btn {
            position: absolute;
            left: 15px;
            top: 15px;
            font: {
                size: 14px;
                weight: 500;
            }
            display: inline-block;
            background-color: $main-color;
            color: $white-color;
            padding: 4px 20px;
        }
    }
    .product-content {
        padding: 25px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 700
            }
        }
        .rating {
            margin: {
                top: 10px;
                bottom: 10px;
            }
            i {
                font-size: 14px;
                color: #fd5d24;
                margin: {
                    left: 1px;
                    right: 1px;
                };
            }
        }
        .price {
            display: block;
            color: $paragraph-color;
        }
        .btn {
            margin-top: 15px;
        }
        .btn-secondary {
            background-color: $white-color;

            &:hover, &:focus {
                background-color: $main-color;
            }
        }
    }
    &:hover {
        .btn-secondary {
            background-color: $main-color;
            color: $white-color;
        }
    }
}

/*================================================
Product Details Area CSS
=================================================*/
.product-details-desc {
    h3 {
        margin-bottom: 13px;
        font: {
            size: 20px;
            weight: 700;
        }
    }
    p {
        margin-bottom: 0;
    }
    .price {
        margin-bottom: 15px;

        span {
            color: $paragraph-color;

            &.old-price {
                text-decoration: line-through;
                color: #c1bdbd;
            }
        }
    }
    .product-review {
        margin-bottom: 15px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;

            i {
                color: #ffba0a;
                margin-right: 3px;
            }
        }
        .rating-count {
            display: inline-block;
            color: $black-color;
            border-bottom: 1px solid $black-color;
            line-height: initial;

            &:hover {
                color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .product-add-to-cart {
        margin-top: 20px;

        .input-counter {
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 40px;
                height: 100%;
                line-height: 48px;
                transition: $transition;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            input {
                height: 45px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: $black-color;
                }
            }
        }
        .btn {
            height: 45px;
            top: -2px;
            position: relative;

            i {
                margin-right: 2px;
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;

        input {
            display: none;
        }
        .cbx {
            margin: auto;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: $transition;

                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: $transition;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $black-color;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: $transition;
                    }
                }
                &:last-child {
                    padding-left: 4px;
                    color: $paragraph-color;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $black-color;
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: $black-color;
            border-color: $black-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
        .btn {
            display: block;
        }
    }
    .custom-payment-options {
        margin-top: 20px;

        span {
            display: block;
            color: $paragraph-color;
            margin-bottom: 8px;
        }
        .payment-methods {
            a {
                display: inline-block;
                margin-right: 5px;

                img {
                    width: 40px;
                }
            }
        }
    }
}
.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}
.products-details-tab {
    margin-top: 50px;

    .tabs {
        list-style-type: none;
        margin-bottom: -1px;
        padding: {
            left: 0;
        }
        li {
            display: inline-block;
            line-height: initial;
            margin-right: 15px;

            a {
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                color: $paragraph-color;
                border: 1px dashed #eeeeee;
                padding: {
                    top: 15px;
                    bottom: 15px;
                    right: 30px;
                    left: 48px;
                }
                font: {
                    weight: 700;
                    size: 14px;
                }
                .dot {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 30px;
                    width: 12px;
                    height: 12px;
                    border: 1px solid $paragraph-color;
                    transition: $transition;

                    &::before {
                        position: absolute;
                        top: 0;
                        content: '';
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $paragraph-color;
                        margin: 1px;
                        transition: $transition;
                    }
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;

                    .dot {
                        border-color: $white-color;

                        &::before {
                            background: $white-color;
                        }
                    }
                }
            }
            &.current {
                a {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;

                    .dot {
                        border-color: $white-color;

                        &::before {
                            background: $white-color;
                        }
                    }
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tab_content {
        border: 1px dashed #eeeeee;
        padding: 30px;

        .tabs_item {
            .products-details-tab-content {
                p {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .additional-information {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
                    text-align: left;

                    li {
                        border: 1px solid #eeeeee;
                        border-bottom: none;
                        padding: 10px 15px;
                        color: $paragraph-color;

                        &:last-child {
                            border-bottom: 1px solid #eeeeee;;
                        }
                        span {
                            display: inline-block;
                            width: 30%;
                            font-weight: 600;
                            color: $black-color;
                        }
                    }
                }
                h3 {
                    margin-bottom: 15px;
                    font: {
                        size: 20px;
                        weight: 700;
                    }
                }
                .product-review-form {
                    .review-title {
                        position: relative;

                        .rating {
                            display: inline-block;

                            .fas.fa-star {
                                color: #ffba0a;
                            }
                            i {
                                color: #ebebeb;
                                font-size: 14px;
                                margin-right: 3px;
                            }
                        }
                        p {
                            margin-bottom: 0;
                            display: inline-block;
                            padding-left: 5px;
                        }
                        .btn {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .review-comments {
                        margin-top: 35px;

                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;

                            .rating {
                                .fas.fa-star {
                                    color: #ffba0a;
                                }
                                i {
                                    font-size: 14px;
                                    color: #ebebeb;
                                    margin-right: 3px;
                                }
                            }
                            h3 {
                                font-size: 17px;
                                margin: {
                                    top: 10px;
                                    bottom: 10px;
                                }
                            }
                            span {
                                margin-bottom: 10px;
                                font-size: 13px;
                                display: block;

                                strong {
                                    font-weight: 600;
                                }
                            }
                            p {
                                margin-bottom: 0;
                            }
                            .review-report-link {
                                position: absolute;
                                right: 0;
                                color: $paragraph-color;
                                top: 40px;
                                text-decoration: underline;

                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .review-form {
                        margin-top: 30px;

                        form {
                            .form-group {
                                margin-bottom: 20px;

                                label {
                                    font: {
                                        size: 14px;
                                        weight: 500;
                                    }
                                    color: $paragraph-color;
                                    text-transform: uppercase;
                                }
                                textarea {
                                    padding-top: 15px;
                                }
                            }
                            .btn {
                                margin-top: 0;
                            }
                            .review-rating {
                                margin-bottom: 1rem;

                                p {
                                    margin-bottom: 5px;
                                }
                                .star-source {
                                    width: 0;
                                    height: 0;
                                    margin-top: 5px;
                                    visibility: hidden;

                                    svg {
                                        width: 1.5rem;
                                        height: 1.5rem;
                                        padding: 0 0.15rem;
                                    }
                                }
                                .star-rating {
                                    display: flex;
                                    flex-direction: row-reverse;
                                    justify-content: center;
                                    width: 105px;

                                    label {
                                        cursor: pointer;
                                        margin-bottom: 0;

                                        .star {
                                            color: transparent;
                                            transition: color 0.2s ease-in-out;
                                        }
                                    }
                                    label:hover ~ label .star, svg.star:hover, input[name="star"]:focus ~ label .star, input[name="star"]:checked ~ label .star {
                                        color: #f2b01e;
                                    }
                                    svg {
                                        width: 1.5rem;
                                        height: 1.5rem;
                                        padding: 0 0.15rem;
                                    }
                                    input {
                                        &[name="star"] {
                                            display: inline-block;
                                            width: 0;
                                            opacity: 0;
                                            margin-left: -2px;
                                        }
                                        &[name="star"]:checked {
                                            + label {
                                                animation: scaleup 1s;

                                                .star {
                                                    animation: starred 0.5s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.related-products-area {
    margin-top: 50px;

    .section-title {
        margin-bottom: 30px;
    }
    .single-product-box {
        margin: {
            bottom: 0;
            top: 30px;
        }
    }
}

/*================================================
Cart CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 0 0 15px;
                    text-transform: uppercase;
                    border: none;
                    font: {
                        weight: 700;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: $paragraph-color;
                    padding: {
                        left: 0;
                        right: 0;
                    }
                    font-size: 14px;
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    }
                    &.product-thumbnail {
                        a {
                            display: block;

                            img {
                                width: 80px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            color: $paragraph-color;
                            font-weight: 600;
                            display: inline-block;

                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    &.product-price {
                        span {
                            font-weight: 500;
                        }
                    }
                    &.product-subtotal {
                        .remove {
                            color: red;
                            float: right;
                            position: relative;
                            top: -1px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;

                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 48px;
                                transition: $transition;

                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $black-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: $black-color;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;

                        span {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
.cart-buttons {
    margin-top: 30px;
}
.cart-totals {
    background: $white-color;
    padding: 40px;
    max-width: 620px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: {
        left: auto;
        top: 50px;
    }
    h3 {
        margin-bottom: 25px;
        font: {
            size: 20px;
            weight: 700;
        }
    }
    ul {
        padding: 0;
        margin: 0 0 25px;
        list-style-type: none;

        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: $black-color;
            overflow: hidden;
            font: {
                weight: 700;
            }
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                border: {
                    top: none;
                }
            }
            span {
                float: right;
                color: $paragraph-color;
                font-weight: normal;
            }
        }
    }
}

/*================================================
Checkout CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 18px 20px 15px;
    margin-bottom: 65px;
    border-top: 3px solid $main-color;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -35px;
        width: 100%;
        height: 1px;
        background: #eeeeee;
    }
    i {
        color: $main-color;
        margin-right: 2px;
    }
    span {
        display: inline-block;
        font-weight: 500;
        color: $main-color;

        a {
            display: inline-block;
            color: $black-color;
            font: {
                size: 14px;
            }
            &:hover, &:focus {
                color: $main-color;
            }
        }
    }
}
.checkout-area {
    .title {
        margin-bottom: 25px;
        font: {
            size: 20px;
            weight: 700;
        }
    }
}
.billing-details {
    .form-group {
        margin-bottom: 20px;

        label {
            display: block;
            color: $paragraph-color;
            margin-bottom: 10px;
            font: {
                size: 14px;
                weight: 500;
            }
            .required {
                color: red;
            }
        }
        .nice-select {
            float: unset;
            line-height: 45px;
            color: $paragraph-color;
            padding: {
                top: 0;
                bottom: 0;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: $transition;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
        textarea {
            padding-top: 13px;
        }
    }
    .form-check {
        margin-bottom: 20px;

        .form-check-label {
            color: $paragraph-color;
        }
        label {
            position: relative;
            top: 1px;
            font: {
                size: 14px;
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .order-table {
        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding: {
                            left: 20px;
                            top: 15px;
                            right: 20px;
                            bottom: 15px;
                        }
                        font: {
                            weight: 700;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: $paragraph-color;
                        border-color: #eaedff;
                        font-size: 14px;
                        padding: {
                            left: 20px;
                            right: 20px;
                        }
                        &.product-name {
                            a {
                                color: $paragraph-color;
                                display: inline-block;
                                font: {
                                    weight: 500;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: $black-color;
                                font-weight: 600;
                            }
                        }
                        &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;

        .payment-method {
            p {
                font: {
                    size: 14px;
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        + label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            font: {
                                weight: 600;
                            }
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;

                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 3px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: $main-color;
                                position: absolute;
                                top: 6px;
                                left: 3px;
                                border-radius: 50%;
                                transition: $transition;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .btn {
            &.order-btn {
                display: block;
                margin-top: 25px;
            }
        }
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    position: relative;
    z-index: 1;

    .coming-soon-content {
        height: 100vh;
        position: relative;
        padding: {
            left: 100px;
            right: 100px;
        }
        .logo {
            position: absolute;
            left: 100px;
            top: 30px;
        }
        h3 {
            margin: {
                bottom: 25px;
            }
            line-height: 1.4;
            font: {
                size: 32px;
                weight: 700;
            }
        }
        form {
            max-width: 520px;
            .form-control {
                height: 52px;
                border-color: #e6e6e6;
                padding-left: 15px;
                font-weight: 500;
                border-radius: 5px;

                &::placeholder {
                    color: #999999;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            .btn {
                display: block;
                width: 100%;
                padding: 15px 28px;
                border-radius: 5px;
            }
            p {
                margin: {
                    bottom: 0;
                    top: 15px;
                }
            }
        }
        .social {
            position: absolute;
            left: 100px;
            bottom: 30px;
            text-align: center;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    margin: 0 4px;

                    a {
                        width: 35px;
                        height: 35px;
                        line-height: 37px;
                        border-radius: 50%;
                        background-color: #dddddd;
                        color: $black-color;
                        font-size: 14px;

                        &.twitter {
                            background-color: #1da1f2;
                            color: $white-color;
                        }
                        &.youtube {
                            background-color: #ff0000;
                            color: $white-color;
                        }
                        &.facebook {
                            background-color: #3b5998;
                            color: $white-color;
                        }
                        &.linkedin {
                            background-color: #0077b5;
                            color: $white-color;
                        }
                        &.instagram {
                            background-color: #c13584;
                            color: $white-color;
                        }
                        &:hover {
                            background-color: $black-color;
                            color: $white-color;
                        }
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .coming-soon-time {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        z-index: 1;
        background: {
            image: url(assets/img/coming-soon-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        img {
            display: none;
        }
        #timer {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            max-width: 420px;
            margin: {
                left: auto;
                right: auto;
            }
            div {
                width: 155px;
                height: 155px;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                display: inline-block;
                color: $white-color;
                line-height: initial;
                padding-top: 40px;
                margin: {
                    right: 15px;
                    left: 15px;
                    bottom: 15px;
                    top: 15px;
                }
                font: {
                    size: 45px;
                    weight: 700;
                }
                span {
                    text-transform: uppercase;
                    display: block;
                    margin-top: 5px;
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                }
            }
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #000000;
            opacity: .6;
            z-index: -1;
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    padding-top: 100px;
}
.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        a {
            display: inline-block;
        }
        p {
            font-size: 14px;
            margin: {
                bottom: 0;
                top: 17px;
            }
        }
    }
    h3 {
        text-transform: uppercase;
        margin-bottom: 23px;
        font: {
            size: 17px;
            weight: 700;
        }
    }
    .useful-links-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 11px;
            font-size: 14px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .widget-services-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 11px;
            font-size: 14px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: $paragraph-color;
            position: relative;
            margin-bottom: 11px;
            font-size: 14px;
            padding-left: 23px;

            i {
                position: absolute;
                left: 0;
                top: -1px;
                color: $black-color;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .newsletter-box {
        margin-top: 20px;

        h4 {
            margin-bottom: 12px;
            font: {
                size: 17px;
                weight: 700;
            }
        }
        .newsletter-form {
            position: relative;

            button {
                position: absolute;
                right: 0;
                top: 0;
                height: 45px;
                width: 50px;
                border: none;
                background-color: $main-color;
                color: $white-color;
                transition: $transition;

                i {
                    &::before {
                        font-size: 20px;
                    }
                }
                &:hover {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
            .validation-danger {
                color: red;
                font-size: 13px;
                margin-top: 5px;
            }
            .validation-success {
                margin-top: 5px;
                font-size: 13px;
            }
        }
    }
}
.copyright-area {
    margin-top: 70px;
    border-top: 1px solid #eeeeee;
    padding: {
        top: 25px;
        bottom: 25px;
    }
    p {
        a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        text-align: right;
        list-style-type: none;

        li {
            display: inline-block;
            margin-left: 13px;

            a {
                color: #696969;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
    bottom: 25px;
	right: 0;
    background-color: $black-color;
    color: $white-color;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 40px;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    transition: .9s;

    &.active {
        right: 25px;
        opacity: 1;
        visibility: visible;
    }
    &:hover, &:focus {
        background-color: $main-color;
        color: $white-color;
    }
}

.mobile-only{
    display: none;

    @media all and (min-width:0px) and (max-width: 500px) {
        display: block;
    }
}

.not-mobile-only{
    display: none;

    @media all and (min-width:500px) {
        display: block;
    }
}
